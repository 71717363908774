import { Link, PageProps } from 'gatsby'
import * as React from 'react'
import { ContentSection } from '../components/content/ContentSection'
import Layout from '../components/Layout'
import { IntegrationsSection } from '../components/IntegrationsSection'
import GetStarted from '../components/GetStarted'
import ArrowRightBoxIcon from 'mdi-react/ArrowRightBoxIcon'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'

export const CodeSearchPage: React.FunctionComponent<PageProps> = props => (
    <Layout
        location={props.location}
        meta={{
            title: '代码搜索 - CodeIN',
            description: '全局快速搜索代码',
            image: 'https://home.codein.dev/codein.png',
        }}
        heroAndHeaderClassName="code-search-page__hero-and-header"
        hero={
            <div className="container pb-4">
                <div className="row">
                    <div className="col-lg-6 mb-lg-6 mt-6">
                        <div className="text-uppercase">代码搜索</div>
                        <h1 className="display-2 font-weight-bold mb-0">搜索代码</h1>
                        <h1 className="display-2 font-weight-bold mb-0">阅读开源代码</h1>
                        <p className="home__semiwide-paragraph my-5 display-4">
                            CodeIN支持任意代码搜索。借助于CodeIN方法跳转和方法定义查找，阅读开源大型项目将变得非常方便
                        </p>
                        <div className="pt-1">
                            <Link className="btn btn-primary mr-3" to="/contact" title="联系我们帮助你完成私有部署">
                                部署咨询 <ArrowRightIcon className="ml-1" />
                            </Link>
                            <a
                                className="btn btn-outline-primary my-3"
                                href="https://codein.dev/search"
                                title="尝试搜索开源项目"
                            >
                                开始搜索 <ArrowRightIcon className="ml-1" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        }
    >
        {/* A search engine built for code */}
        <ContentSection>
            <div className="row">
                <div className="col-lg-6 container video-embed embed-responsive embed-responsive-16by9 ">
                    {/* <iframe
                        className="embed-responsive-item"
                        src="https://www.youtube-nocookie.com/embed/aDU4C9j-hYA?autoplay=0&amp;cc_load_policy=0&amp;start=0&amp;end=0&amp;loop=0&amp;controls=1&amp;modestbranding=1&amp;rel=0"
                        allowFullScreen={true}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder={0}
                        title="AND/OR operators for universal code search"
                    ></iframe> */}
                </div>
                <div className="col-lg-6">
                    <h2 className="display-3 font-weight-bold mb-3">智能代码搜索</h2>
                    <ul className="list-spaced">
                        <li>
                            <strong>覆盖全面</strong> CodeIN支持Github、Gitlab、Git
                            server等常见的代码平台，并且支持大部分
                            编程语言。无论是任何岗位开发，CodeIN都将是你写代码一个强力的伙伴
                        </li>
                        <li>
                            <strong>功能强大</strong>{' '}
                            CodeIN丰富的查询语言，支持仓库、文件、方法、注释等精确查找。对于跨仓库的支持
                            更能让你快速找到问题代码，并且及时修改
                        </li>
                        <li>
                            <strong>灵活扩展</strong>{' '}
                            CodeIN有丰富的扩展能力。支持Chrome、Firefox等常见浏览器。另外也支持VSCode
                            Atom等编辑器插件。此外CodeIN还有完整的API系统，帮助集成任何内部服务
                        </li>
                    </ul>
                </div>
            </div>
        </ContentSection>

        {/* Social proof */}
        <ContentSection className="py-4">
            <div className="row justify-content-center pt-md-4">
                <div className="col-lg-6">
                    <h2 className="display-3 font-weight-bold mb-3">使用CodeIN高效开发</h2>
                    <h5>3倍速学习</h5>
                    <p>无需下载代码既可快捷浏览大型开源项目，云端浏览更加节省本地电脑资源</p>

                    <h5>提升程序员开发体验</h5>
                    <p>全局搜索方法调用，让开发者不在害怕修改接口。从容面对底层代码重构。</p>

                    <h5>快速修复代码漏洞</h5>
                    <p>全局跨仓库搜索可以更加快速的查找修复代码漏洞</p>
                </div>
                <div className="col-lg-6 text-center"></div>
            </div>
        </ContentSection>

        <IntegrationsSection />
    </Layout>
)

export default CodeSearchPage
